import * as React from "react";
import { Helmet } from "react-helmet";

import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import SEO from "../components/seo/SEO";
import Layout from "../components/layout/Layout";

const faqs = [
  {
    question: "How do I start a new Google Meet directly from Slack?",
    answer:
      "After installing the app, you can use /meet in any Slack DM, group or channel. It will generate a new meeting and send a message with a link to join. You can give your meetings a title by including it after the /meet command, for example /meet Important Meeting.",
  },
  {
    question: "How do I join Google Meets with the correct account (authuser)?",
    answer:
      "After you have opened your first meeting generated by /meet you are asked to select your Google Account. This will be the account used for joining all Google Meets from Slack. It means you don't have to manually switch your Google account (for example between personal/work/school) before joining a meeting.",
  },
  {
    question: "Meetings open with the wrong account, how do I reset?",
    answer:
      "To reset which account is used for joining your Google Meets, use /meet help command in Slack. Then open the reset link sent to you in Slack. ",
  },
  {
    question: "My Slack status doesn't update automatically after joining a Meet, what do I do?",
    answer:
      "Unfortunately, this feature only works on paid Slack workspaces. If you are on a paid Slack workspace and your Slack status doesn't update when joining a Meet, check that you have this feature enabled in /meet for Slack App Home. Alternatively, ask your Slack admin to reinstall the app.",
  },
  {
    question: "I am concerned about security and privacy.",
    answer:
      "The /meet for Slack app is approved by Google and Slack. It will only request permissions it actually needs to function. We don't access any unnecessary user or workspace data.",
  },
  // More questions...
];

export default function Faq() {
  return (
    <Layout>
      <SEO />
      <Helmet>
        <title>Help and FAQs - /meet 4 Slack</title>
      </Helmet>
      <Navbar />
      <div className="bg-white pt-16">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <h2 className="text-3xl font-extrabold text-gray-900">Frequently asked questions</h2>
              <p className="mt-4 text-lg text-gray-500">
                Can't find the answer you're looking for? Reach out on{" "}
                <a
                  href="mailto:help@meetslack.com"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  help@meetslack.com
                </a>
              </p>
            </div>
            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-12">
                {faqs.map((faq) => (
                  <div key={faq.question}>
                    <dt className="text-lg leading-6 font-medium text-gray-900">{faq.question}</dt>
                    <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
